import { combineReducers } from "redux";

import ThemeOptions from "./ThemeOptions";
import ProfileReducer from "./profile";
import AjaxReducer from "./ajax";
import PathReducer from './path'
import LastViewURLReducer from "./lastViewURL";
import ImpersonateProfileReducer from "./impersonate"

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  LastViewURLReducer,
  ImpersonateProfileReducer,
  ProfileReducer,
  PathReducer
});
