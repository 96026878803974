import React, { Component, Suspense } from "react"
import { toast, ToastContainer } from "react-toastify"
import { connect } from "react-redux"
import { Route, withRouter } from "react-router-dom"
import DocumentMeta from "react-document-meta"
import _ from "lodash"

import TemplateHeader from "components/Header"
import TemplateContainerMain from "components/Template"
import TemplateSidemenu from "components/Menu/Sidemenu"
import LoadingOverlay from "components/Indicator/LoadingOverlay"

import { getProfile } from "actions/profile"
import impersonateLogOut from "actions/impersonateLogOut"
import { routes, utilRoutes, superAdminRoutes } from "./router"
import { getItem, clearItem } from "utils/tokenStore"
import { setLastViewURL } from "reducers/lastViewURL";

const PrefixURL = "/dashboard"

const meta = {
  meta: {
    name: {
      robots: "noindex"
    }
  }
}

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
)

class Dashboard extends Component {
  componentDidMount = () => {
    if (!getItem("ZITRON_ACCESS_TOKEN")) {
      this.props.setLastViewURL({ pathname: this.props.location.pathname, search: this.props.location.search });
      this.props.history.push("/login")
      clearItem("ZITRON_IMPERSONATE_ACCESS_TOKEN")
    } else if (this.props.data.LastViewURLReducer.last_view_url) {
      const lasView = this.props.data.LastViewURLReducer.last_view_url;
      this.props.setLastViewURL("")
      this.props.history.push(lasView);
    }

    if (window.location.href.includes("/admin-impersonate")) {
      window.addEventListener("unload", (event) => {
        this.props.impersonateLogOut();
      });
    }

    if (window.location.href.includes("/admin-impersonate") && !getItem("ZITRON_IMPERSONATE_ACCESS_TOKEN")){
      this.props.history.push("/dashboard/main")
    }
  };

  render = () => {
    const tmpProfile = window.location.href.includes("/admin-impersonate")
      ? this.props.data.ImpersonateProfileReducer
      : this.props.data.ProfileReducer

    const modules = tmpProfile.groupedModule
    const parentModules = tmpProfile.parentModule

    const formattedData = _.map(parentModules, (parentModule, id) => {
      return {
        ...parentModule,
        routes:
          modules[id]?.map(route => ({
            ...route,
            routes: modules[route.id] || []
          })) || []
      }
    })

    const filteredRoutes = _.flatMap(formattedData, item => {
      return _.filter(routes, route => {
        return (
          route.parent === item.name &&
          item.routes.filter(routeItem => routeItem.is_accessible) &&
          item.routes.length > 0
        )
      })
    })

    const tmpPath = window.location.href.includes("/admin-impersonate")
      ? "/admin-impersonate"
      : ""
    
    const renderedRoutes = _.flatMap([...filteredRoutes], item => {
      if (item.routes) {
        return _.flatMap(item.routes, route => {
          if (route.path) {
            return (
              <SuspenseWrapper>
                <Route
                  exact={route.exact}
                  path={`${tmpPath}${PrefixURL}${route.path}`}
                  render={props => route.render(props)}
                />
              </SuspenseWrapper>
            )
          }

          if (route.content) {
            return _.map(route.content, nestedRoute => (
              <SuspenseWrapper>
                <Route
                  exact={nestedRoute.exact}
                  path={`${tmpPath}${PrefixURL}${nestedRoute.path}`}
                  render={props => nestedRoute.render(props)}
                />
              </SuspenseWrapper>
            ))
          }

          return null
        })
      }
      return []
    })

    const adminRoutesComponents = superAdminRoutes.map(route => (
      <SuspenseWrapper>
        <Route
          path={`${tmpPath}${PrefixURL}${route.path}`}
          render={props => route.render(props)}
        />
      </SuspenseWrapper>
    ))

    return (
      <DocumentMeta {...meta}>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => {}}
            history={this.props.history}
            user={{
              name: "Test user",
              email: "testuser@gmail.com"
            }}
          />
          <TemplateSidemenu history={this.props.history} />
          <div className="app-main">
            <div className="app-main__outer">
              <div className="app-main__inner">
                {[...renderedRoutes, ...adminRoutesComponents]}
                {utilRoutes.map(route => (
                  <SuspenseWrapper>
                    <Route
                      exact={route.exact}
                      path={`${tmpPath}${PrefixURL}${route.path}`}
                      render={props => route.render(props)}
                    />
                  </SuspenseWrapper>
                ))}
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </DocumentMeta>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default connect(mapStateToProps, {
  getProfile,
  setLastViewURL,
  impersonateLogOut,
  withRouter
})(Dashboard)
