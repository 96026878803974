import React, { useState } from "react";
import { Button } from "reactstrap";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";

import "./index.scss"
import WithLogin from "./action";

const Login = (props) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (username === null || username === "" || password === null || password === "") {
        return false;
      }

      props.onClickLogin({
        username: username,
        password: password
      })
    }
  };

  return (
    <>
      <TemplateContainerMain>
        <div className="h-100">
          <Row className="h-100">
            <Col span={24} className="login-landing-page">
              <Col xl={8} lg={12} md={18} sm={24} xs={24} className="mx-auto app-login-box bg-gray-100 rounded shadow-sm">
                <div
                  style={{
                    padding: "25px",
                    borderRadius: "10px"
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <h3
                      style={{
                        color: "#cb1619",
                        fontWeight: "700"
                      }}
                      className="mb-1"
                    >
                      Welcome!
                    </h3>
                    <div className="mb-4 text-grey font-weight-heavy">Fill in your account information.</div>
                  </div>
                  <div className="login-input">
                    <Input
                      placeholder="User ID"
                      autoComplete="username"
                      status={username === "" ? "error" : null}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <Input.Password
                      placeholder="Password"
                      autoComplete="current-password"
                      status={password === "" ? "error" : null}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  {props.errorMessage && <span className="text-danger">{props.errorMessage}</span>}

                  <Row className="mx-1 my-4"/>
                  <Button
                    className="font-weight-heavy mb-3"
                    color="primary btn-block"
                    size="lg"
                    type="button"
                    disabled={username === null || username === "" || password === null || password === ""}
                    onClick={() => {
                      props.onClickLogin({
                        username: username,
                        password: password
                      })
                    }}
                  >
                    Login
                  </Button>
                  <a
                    className="d-flex justify-content-center align-items-center forget-pw-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/forgot-password");
                    }}
                  >
                    Forget password? Reset here.
                  </a>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
        {props.onLoadLogin && <LoadingOverlay />}
      </TemplateContainerMain>
    </>
  );
};

export default WithLogin(Login);
