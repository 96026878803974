import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Row, Col } from "antd";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import { HiOutlineExclamation } from "react-icons/hi";
import moment from "moment";

import CustomCard from 'components/Card';
import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PasswordInput from "components/Input/password";
import WithForgotPw from "./actions";

const ForgotPw = (props) => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()

  const [formData, updateFormData] = useState({})
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (props.match.path === "/reset-password") {
      props.verifyResetPassword(query.get("token"))
    }
  }, [props.match.path])

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (formData.password === null || formData.password === "" || formData.password_confirmation === null || formData.password_confirmation === "") {
        return false;
      }
    }
  };

  const handleErrors = () => {
    let errors = [];
    
    if (_.isEmpty(formData.password)) {
      errors.push({ field: 'password', message: 'Password is required' });
    }

    if (_.isEmpty(formData.password_confirmation)) {
      errors.push({ field: 'password_confirmation', message: 'Confirm Password is required' });
    }

    if (formData.password?.length < 8) {
      errors.push({ field: 'password', message: 'Password must be at least 8 characters long', code: 'too_small' });
    }

    if (formData.password_confirmation?.length < 8) {
      errors.push({ field: 'password_confirmation', message: 'Confirm Password must be at least 8 characters long', code: 'too_small' });
    }

    if (formData.password?.length >= 8 && formData.password_confirmation?.length >= 8 && formData.password !== formData.password_confirmation) {
      errors = "Password must be equal"
    } else {
      errors = [...errors]
    }

    setErrors(errors);

    if (errors.length === 0) {
      props.resetPassword(
        { password: formData.password, password_confirmation: formData.password_confirmation },
        query.get("token")
      )
    }
  };

  const handleChange = (field, value) => {
    const tmp = { ...formData, [field]: value }
    updateFormData(tmp)

    let tmpErrors = errors
    if (Array.isArray(tmpErrors)) {
      if (field === "password" && value.length >= 8) {
        tmpErrors = tmpErrors.filter(
          error => !(error.field === "password" && error.code === "too_small")
        )
      }

      if (field === "password_confirmation" && value.length >= 8) {
        tmpErrors = tmpErrors.filter(
          error => !(error.field === "password_confirmation" && error.code === "too_small")
        )
      }
    }

    if (tmp.password_confirmation === tmp.password) {
      tmpErrors = []
    }

    setErrors(tmpErrors)
  }

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const token = url.hash.split('?').filter(function (el) { if (el.match('token') !== null) return true; })[0].split('=')[1];
  //   if (token) {
  //     console.log("Token:", token);
  //   } else {
  //     console.log("token not found");
  //   }
  // }, []);

  return (
    <TemplateContainerMain>
      <div className="h-100">
        <Row className="h-100">
          <Col span={24} className="login-landing-page">
            <Col xl={8} lg={12} md={18} sm={24} xs={24} className="mx-auto app-login-box bg-gray-100 rounded shadow-sm">
              <AvForm
                style={{
                  padding: "25px",
                  borderRadius: "10px"
                }}
              >
                {
                  (!_.isEmpty(props.userAccount) && moment() < moment(props.userAccount.reset_password_token_expired_at)) ? (
                    <>
                      <div className="d-flex flex-column align-items-center">
                        <h4
                          style={{
                            color: "#cb1619",
                            fontWeight: "700"
                          }}
                          className="mb-1"
                        >
                          Reset Password
                        </h4>
                        <div className="mb-4 text-grey font-weight-heavy">Fill in your new password below.</div>
                      </div>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>
                            <CustomCard>
                              <span style={{fontWeight: 'bold'}}>User ID: {props.userAccount.username}</span>
                            </CustomCard>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div>
                            <PasswordInput
                              context={"password"}
                              containerClass={"password-input"}
                              inputStyle={{ borderRadius: "6px" }}
                              status={formData.password === "" ? "error" : null}
                              placeholder={"New Password"}
                              value={formData.password}
                              onChange={(e) => handleChange('password', e.target.value)}
                              onKeyPress={handleKeyPress}
                              errors={errors}
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div>
                            <PasswordInput
                              context={"password_confirmation"}
                              containerClass={"password-input"}
                              inputStyle={{ borderRadius: "6px", marginBottom: "8px" }}
                              status={formData.password_confirmation === "" ? "error" : null}
                              placeholder="Confirm Password"
                              value={formData.password_confirmation}
                              onChange={(e) => handleChange('password_confirmation', e.target.value)}
                              onKeyPress={handleKeyPress}
                              errors={errors}
                            />
                          </div>
                          <span className="text-danger">{typeof errors === "string" && errors}</span>
                        </Col>
                      </Row>
                      <Row className="mx-1 mt-3 mb-4" />
                      <Button
                        className="font-weight-heavy mb-3"
                        color="primary btn-block"
                        size="lg"
                        onClick={() => { handleErrors() }}
                      >
                        Reset Password
                      </Button>
                    </>
                  ) : (
                    <h5
                      className="text-center"
                      style={{ color: "#cb171a" }}
                    >
                      <HiOutlineExclamation
                        style={{
                          marginRight: "10px",
                          fontSize: "25px"
                        }}
                      />
                      Your token is invalid !
                    </h5>
                  )
                }
              </AvForm>
            </Col>
          </Col>
        </Row>
      </div>
      {props.onLoadLogin && <LoadingOverlay />}
    </TemplateContainerMain>
  );
};

export default WithForgotPw(ForgotPw);