export default () => {
  if (window.location.host.indexOf("localhost") !== -1) {
    return "https://zerp-api-staging.navarch.app";
  }
  if (window.location.host.indexOf("staging") !== -1) {
    return "https://zerp-api-staging.navarch.app";
  }
  if (window.location.host.indexOf("d1cjxfp380ej9r.cloudfront.net") !== -1) {
    return "https://zerp-api-uat.navarch.app";
  }
  if (window.location.host.indexOf("uat") !== -1) {
    return "https://zerp-api-uat.navarch.app";
  }
  if (window.location.host.indexOf("migration") !== -1) {
    return "https://zerp-api-migration.navarch.app";
  }
  return "https://zerp-api-staging.navarch.app";
};
