import { GET_IMPERSONATE_PROFILE, AUTH_IMPERSONATE_LOGOUT } from "../actions/type";
import _ from "lodash"

const initialState = {
  profile: {},
  impersonateAccessToken: null,
  module: [],
  groupedModule: {},
  parentModule: {},
  subModule: [],
  organisations: [],
  branches: [],
};

function buildTree(groupedModule, moduleId) {
  return (groupedModule[moduleId] || []).map(module => ({
    ...module,
    children: buildTree(groupedModule, module.id)
  }));
}

export const setImpersonateUserProfile = payload => ({
  type: GET_IMPERSONATE_PROFILE,  
  payload,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_IMPERSONATE_PROFILE: {
      const { userAccount, userPermissions, accessToken } = action.payload;
      return {
        ...state,
        profile: userAccount,
        impersonateAccessToken: accessToken,
        module: userPermissions?.modules,
        groupedModule:  _.groupBy(userPermissions?.modules.filter(module => module.is_accessible === true), "parent_module_id"),
        parentModule: _.keyBy(userPermissions?.modules.filter(module => module.parent_module_id === null), 'id'),
        subModule: buildTree( _.groupBy(userPermissions?.modules, "parent_module_id"), null),
        organisations: _.orderBy(userPermissions?.organisations, ['name'], ['asc']),
        branches: _.orderBy(_.flatMap(userPermissions?.organisations, ({ id: orgId, branches }) => _.map(branches, branch => ({ ...branch, orgId }))), ['name'], ['asc'])
      }
    }
    case AUTH_IMPERSONATE_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
