import React, { useState } from "react";
import { Button } from "reactstrap";
import { Row, Col, Space, Input } from "antd";
import { AvForm } from "availity-reactstrap-validation";
import _ from "lodash";

import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import WithResetPwForm from "./actions";

const ResetPwForm = (props) => {
  // const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (username === null || username === "") {
        return false;
      }
      props.requestForgetPasswordEmail({ username })
    }
  };

  return (
    <>
      <TemplateContainerMain>
        <div className="h-100">
          <Row className="h-100">
            <Col span={24} className="login-landing-page">
              <Col xl={8} lg={12} md={18} sm={24} xs={24} className="mx-auto app-login-box bg-gray-100 rounded shadow-sm">
                <AvForm
                  style={{
                    padding: "25px",
                    borderRadius: "10px"
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <h4
                      style={{
                        color: "#cb1619",
                        fontWeight: "700"
                      }}
                      className="mb-1"
                    >
                      Reset Password
                    </h4>
                    <span className="text-grey font-weight-heavy">Forgotten your password?</span>
                    <span className="mb-4 text-grey font-weight-heavy">We'll email you instructions to reset your password.</span>
                  </div>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Space className="username-input mb-2" style={{ width: "100%" }}>
                        {/* <Input
                          style={{ borderRadius: "6px" }}
                          placeholder="Email"
                          status={email === "" ? "error" : null}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyPress={handleKeyPress}
                        /> */}
                        <Input
                          style={{ borderRadius: "6px" }}
                          placeholder="User ID"
                          status={username === "" ? "error" : null}
                          onChange={(e) => setUsername(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </Space>
                      {props.errorMessage && <span className="text-danger">{props.errorMessage}</span>}
                    </Col>
                  </Row>
                  <Row className="mx-1 my-2"/>
                  <Button
                    className="font-weight-heavy mb-3"
                    color="primary btn-block"
                    size="lg"
                    disabled={username === null || username === ""}
                    onClick={() => props.requestForgetPasswordEmail({ username })}
                  >
                    Reset Password
                  </Button>
                  <a
                    className="d-flex justify-content-center align-items-center forget-pw-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/login");
                    }}
                  >
                    Go back to login page
                  </a>
                </AvForm>
              </Col>
            </Col>
          </Row>
        </div>
        {props.onLoadLogin && <LoadingOverlay />}
      </TemplateContainerMain>
    </>
  );
};

export default WithResetPwForm(ResetPwForm);