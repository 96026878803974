import React, { Component } from "react"
import { connect } from "react-redux"

import Axios from "axios"
import getDomainURL from "utils/api"
import { requestSuccess, requestError } from "utils/requestHandler"
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      requestCount: 0,
      showNewPassword: false,
      showConfirmPassword: false,
      errorMessage: "",
      userAccount: {}
    }

    onChangeHOC = (val, context) => this.setState({ [context]: val })

    load = (param) => {
      this.setState((prevState) => {
        const newCount = param ? prevState.requestCount + 1 : prevState.requestCount - 1
        return {
          loading: newCount > 0,
          requestCount: newCount,
        }
      })
    }

    resetPassword = (dataToSubmit, token) => {
      this.setState({ loading: true }, () => {
        Axios.put(
          `${getDomainURL()}/api/change_forgot_password`,
          dataToSubmit,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => {
            this.setState({ loading: false,
      requestCount: 0, })
            this.props.history.push("/login")
            requestSuccess(
              "You have successfully reset the password. Please proceed to login.", "Success"
            )
          })
          .catch((err) => {
            this.setState({
              errorMessage:
                err.response?.data?.message ??
                "Oops, something happened. Please try again.",
              loading: false,
      requestCount: 0,
            })
          })
      })
    }

    verifyResetPassword = (token) => Get(
      `/api/user_accounts/${token}/verify_reset_password`,
      this.verifyResetPasswordSuccess,
      this.verifyResetPasswordError,
      this.load
    )
    verifyResetPasswordSuccess = (payload) => this.setState({ userAccount: payload.data })
    verifyResetPasswordError = () => {}

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          showNewPassword={this.state.showNewPassword}
          showConfirmPassword={this.state.showConfirmPassword}
          errorMessage={this.state.errorMessage}
          userAccount={this.state.userAccount}
          onLoadLogin={this.state.loading}
          onChangeHOC={this.onChangeHOC}
          resetPassword={this.resetPassword}
          verifyResetPassword={this.verifyResetPassword}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state })
  return connect(mapStateToProps)(WithHOC)
}

export default HOC
