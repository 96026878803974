import React, { useEffect, useState } from "react";
import { Form, Button } from "reactstrap";
import { Row, Col, Space, Input } from 'antd'
import { useLocation } from 'react-router-dom';
import { HiOutlineExclamation, HiOutlineCheckCircle } from "react-icons/hi";
import _ from "lodash";

import CustomCard from 'components/Card';
import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PasswordInput from "components/Input/password";
import WithVerifyRegistration from "./action";

const Registration = (props) => {

  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()

  const [formData, updateFormData] = useState({})

  useEffect(() => {
    if (props.match.path === "/register") {
      props.verifyRegistration(query.get("token"))
    }
  }, [props.match.path])

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (formData.password === null || formData.password === "" || formData.password_confirmation === null || formData.password_confirmation === "") {
        return false;
      }

      props.register({ ...formData, token: query.get("token") })
    }
  };

  const handleChange = (field, value) => {
    const tmp = { ...formData, [field]: value }
    updateFormData(tmp)

    let tmpErrors = props.registerError
    if (Array.isArray(tmpErrors)) {
      if (field === "password" && value.length >= 8) {
        tmpErrors = tmpErrors.filter(
          error => !(error.field === "password" && error.code === "too_small")
        )
      }

      if (field === "password_confirmation" && value.length >= 8) {
        tmpErrors = tmpErrors.filter(
          error => !(error.field === "password_confirmation" && error.code === "too_small")
        )
      }
    }

    if (formData.password?.length >= 8 && formData.password_confirmation?.length >= 8) {
      if (tmp.password_confirmation === tmp.password) {
        tmpErrors = []
      }
    }

    props.onChangeUserAccHOC("registerError", tmpErrors)
  }

  return (
    <TemplateContainerMain>
      <div className="h-100">
        <Row className="h-100">
          <Col span={24} className="login-landing-page">
            <Col xl={8} lg={12} md={18} sm={24} xs={24} className="mx-auto app-login-box bg-gray-100 rounded shadow-sm">
              <div
                style={{
                  padding: "25px",
                  borderRadius: "10px"
                }}
              >
                {(!_.isEmpty(props.userAccount) && props.userAccount.register_at === null) && (
                  <>
                    <div className="d-flex flex-column align-items-center">
                      <h5 className="mb-1" style={{ color: "#cb1619", fontWeight: "700" }}>Registration</h5>
                      <span className="mb-4 text-grey font-weight-heavy">Fill in your password to register.</span>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div>
                            <CustomCard>
                              <span style={{fontWeight: 'bold'}}>User ID: {props.userAccount.username}</span>
                            </CustomCard>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div>
                            <PasswordInput
                              context={"password"}
                              containerClass={"password-input"}
                              inputStyle={{ borderRadius: "6px" }}
                              status={formData.password === "" ? "error" : null}
                              placeholder={"Password"}
                              value={formData.password}
                              instructions={'Password must be at least 8 characters long'}
                              onChange={(e) => handleChange('password', e.target.value)}
                              onKeyPress={handleKeyPress}
                              errors={props.registerError}
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div>
                            <PasswordInput
                              context={"password_confirmation"}
                              containerClass={"password-input"}
                              inputStyle={{ borderRadius: "6px", marginBottom: "8px" }}
                              status={formData.password_confirmation === "" ? "error" : null}
                              placeholder="Confirm Password"
                              value={formData.password_confirmation}
                              instructions={'Confirm Password must be at least 8 characters long'}
                              onChange={(e) => handleChange('password_confirmation', e.target.value)}
                              onKeyPress={handleKeyPress}
                              errors={props.registerError}
                            />
                          </div>
                          <span className="text-danger">{typeof props.registerError === "string" && props.registerError}</span>
                        </Col>
                    </Row>


                    <Row className="mx-1" />
                    <Button
                      color="primary btn-block my-4"
                      size="lg"
                      onClick={() => props.register({ ...formData, token: query.get("token") })}
                    >
                      Register Now
                    </Button>
                  </>
                )}
                {props.successRegistration && (
                  <>
                    <h5
                      className="text-center py-2"
                      style={{ color: "#039855" }}
                    >
                      <HiOutlineCheckCircle 
                        style={{
                          marginRight: "10px",
                          fontSize: "25px"
                        }}
                      />
                        Your registration is successful!
                    </h5>
                    <Button
                      color="primary btn-block mt-4 mb-3"
                      size="lg"
                      onClick={() => props.history.push("/login")}
                    >
                      Login Now
                    </Button>
                  </>
                )}
                {props.invalidToken && (
                  <h5 className="text-center" style={{ color: "#cb171a" }}>
                    <HiOutlineExclamation 
                      style={{
                        marginRight: "10px",
                        fontSize: "25px"
                      }}
                    />
                    Your token is invalid !
                  </h5>
                )}
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      {props.onLoadRegistration && <LoadingOverlay />}
    </TemplateContainerMain>
  )
}

export default WithVerifyRegistration(Registration)
