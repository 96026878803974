import { AUTH_IMPERSONATE_LOGOUT } from "./type";
import { clearItem } from "utils/tokenStore";

const impersonateLogOut = () => {
  return (dispatch) => {
    dispatch(authUserSuccess(""));
    clearItem("ZITRON_IMPERSONATE_ACCESS_TOKEN");
  };
};

const authUserSuccess = (payload) => ({
  type: AUTH_IMPERSONATE_LOGOUT,
  payload,
});

export default impersonateLogOut 

